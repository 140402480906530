<template>
  <section class="settings">
    <Preloader v-if="loading" />
    <div class="container" v-else>
      <div class="portrait" v-if="orientation === 'portrait'">
        <div class="logotype">
          <img src="/img/logotype.svg" alt="">
        </div>
        <div class="inputs">
          <div @click="showDrop(input)" :class="{disabled: input.disabled}" class="input__block" v-for="input of inputs" :key="input.id">
            <input :class="{active: input.showDrop && !input.disabled}" type="text" :placeholder="input.placeholder" v-model="input.value.name">
            <svg :class="{active: input.showDrop && !input.disabled}" width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L8 8L15 0.999999" stroke="white" stroke-width="2" stroke-linejoin="round"/>
            </svg>
            <transition name="fade">
              <div class="drop drop-game" v-if="input.showDrop && input.drop_data && !input.disabled">
                <ul>
                  <li @click.stop="selectElement(input, element)" v-for="element of input.drop_data" :key="element._id">{{ element.name }}</li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
        <div class="actions">
          <button class="btn btn-gradient" @click="startGame">Начать игру</button>
        </div>
      </div>
      <div class="landscape" v-if="orientation === 'landscape'">
        <div class="logotype">
          <img src="/img/logotype.svg" alt="">
        </div>
        <div class="right">
          <div class="inputs">
            <div @click="showDrop(input)" :class="{disabled: input.disabled}" class="input__block" v-for="input of inputs" :key="input.id">
              <input :class="{active: input.showDrop && !input.disabled}" type="text" :placeholder="input.placeholder" v-model="input.value.name">
              <svg :class="{active: input.showDrop && !input.disabled}" width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8 8L15 0.999999" stroke="white" stroke-width="2" stroke-linejoin="round"/>
              </svg>
              <transition name="fade">
                <div class="drop drop-game" v-if="input.showDrop && input.drop_data && !input.disabled">
                  <ul>
                    <li @click.stop="selectElement(input, element)" v-for="element of input.drop_data" :key="element._id">{{ element.name }}</li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
          <div class="actions">
            <button class="btn btn-gradient" @click="startGame">Начать игру</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from 'axios';
import Preloader from '../Preloader';

export default {
  name: 'SettingsMain',
  data() {
    return {
      orientation: this.$store.getters.orientation,
      loading: true,
      inputs: [
        { id: 'genre', placeholder: 'Выберите жанр', disabled: false, value: {_id: '', name: ''}, showDrop: false, drop_data: null },
        { id: 'executor', placeholder: 'Выберите исполнителя', disabled: false, value: {_id: '', name: ''}, showDrop: false, drop_data: null },
        { id: 'author', placeholder: 'Выберите автора', disabled: false, value: {_id: '', name: ''}, showDrop: false, drop_data: null },
      ]
    }
  },
  mounted() {
    Axios
      .get(`/api/mobile/game/settings`, {
        headers: {
          token: this.$store.getters.token
        }
      })
      .then(res => {
        this.loading = false;
        this.inputs[0].drop_data = res.data.genre;
        this.inputs[1].drop_data = res.data.executor;
        this.inputs[2].drop_data = res.data.author;
      })
      .catch(err => {
        console.error(err.response);
      })
  },
  methods: {
    showDrop(input) {
      this.inputs.forEach(el => {
        if (el.id !== input.id) el.showDrop = false;
      })
      input.showDrop = !input.showDrop;
    },
    selectElement(input, element) {
      input.value = element;
      input.showDrop = false;
      this.inputs.forEach(el => {
        if (el.id !== input.id) el.disabled = true;
      })
    },
    startGame() {
      const filter = this.inputs.find(el => el.value._id !== '');
      const option = {};
      if (filter) option[filter.id + '_id'] = filter.value._id;
      this.$store.dispatch('addSettingsGame', option);
      this.$router.push('/play');
    }
  },
  watch: {
    '$store.state.orientation': function() {
      if (this.$store.state.orientation) {
        this.orientation = this.$store.state.orientation;
      }
    }
  },
  components: {
    Preloader
  }
}
</script>

<style lang="scss" scoped>
.settings {
  height: 100%;
  .container {
    height: 100%;
  }
  .portrait, .landscape {
    width: 100%;
    height: 100%;
  }
  .portrait {
    .logotype {
      padding-top: 100px;
      padding-bottom: 80px;
      font-size: 32px;
      text-align: center;
    }
  }
  .landscape {
    display: flex;
    align-items: center;
    .logotype {
      font-size: 32px;
      text-align: center;
      width: 50%;
      margin-right: 10%;
    }
    .right {
      width: 40%;
      margin-top: 30px;
      .actions {
        margin-top: 20px;
      }
    }
  }
  .actions {
    margin-top: 50px;
    button:first-child {
      margin-bottom: 20px;
    }
  }
}

</style>