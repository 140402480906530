<template>
  <SettingsMain />
</template>

<script>
import axios from "axios";
import SettingsMain from "@/components/game/settings/SettingsMain";

export default {
  name: "Settings",
  components: {
    SettingsMain,
  },
};
</script>

<style>
</style>